/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "Segoe-UI"; /*a name to be used later*/
  src: url("./assets/font/Segoe-UI.ttf"); /*URL to font*/
}

ion-scroll[direction="x"] {
  width: 100vw;
}
ion-scroll[direction="y"] {
  height: 100vh;
}

ion-content {
  top: 50px;
}
app-login {
  ion-content {
    top: 0;
  }
}

ion-app {
  --ion-default-font: "Segoe-UI", sans-serif !important;
}

ion-toolbar img {
  width: 115px;
}
// .action-sheet-inputs {
//   display: grid;
//   grid-template-columns: 1fr 1fr auto;
//   grid-gap: 10px;
//   align-items: center;
//   padding: 10px;

//   input {
//     padding: 12px;
//     border: 1px solid #ddd;
//     border-radius: 5px;
//   }

//   button {
//     padding: 12.5px 30px;
//     border: 0;
//     border-radius: 100px;
//     background-color: #2ba8fb;
//     color: #ffffff;
//     font-weight: bold;
//     transition: all 0.5s;
//     -webkit-transition: all 0.5s;
//   }

//   button:hover {
//     background-color: #6fc5ff;
//     box-shadow: 0 0 20px #6fc5ff50;
//     transform: scale(1.1);
//   }

//   button:active {
//     background-color: #3d94cf;
//     transition: all 0.25s;
//     -webkit-transition: all 0.25s;
//     box-shadow: none;
//     transform: scale(0.98);
//   }
// }

// @media (max-width: 400px) {
//   .action-sheet-inputs {
//     grid-template-columns: 1fr;
//   }
// }
.select-flex-item {
  margin-right: 10px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px;
  flex: 1 1 auto;
}
.action-sheet-inputs {
  display: flex;
  flex-wrap: wrap;

  .input-group {
    flex: 1;
    flex-shrink: 50%;
    margin-right: 10px;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 5px;
  }

  button {
    flex: 1;
    padding: 12.5px 30px;
    border: 0;
    margin: 5px;
    border-radius: 100px;
    background-color: #2ba8fb;
    color: #ffffff;
    font-weight: bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

  button:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }

  button:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }

  .tooltip {
    display: none;
    position: absolute;
    background-color: #ff0000;
    color: #ffffff;
    padding: 5px;
  }

  .tooltip.active {
    display: block;
  }
}

@media (max-width: 400px) {
  .action-sheet-inputs {
    flex-direction: column;
  }

  .textbox {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.menu-toggle {
  width: 20px !important;
}
.my-custom-menu {
  --width: 200px;
  * {
    --background: var(--ion-color-secondary);
    background: transparent;
    color: #fff;
    top: 0;
  }
  ion-icon {
    color: var(--ion-color-primary);
  }
  ion-footer {
    background: var(--ion-color-secondary);
    text-align: center;
  }
}
.dropdown_img_icon {
  width: 25px;
}

ion-content {
  background-color: #f2f3f6;
}
li-ionic4-datepicker {
  display: flex;
  input {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5;
    // width: 90% !important;
    color: #495057;
    background-color: #f4f6fa;
    background-clip: padding-box;
    border: 0 !important;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0 !important;
  }
  ion-icon {
    display: none;
  }
  .ng-valid {
    ion-icon {
      display: block !important;
      position: relative !important;
      padding: 10px 2px 0 !important;
      font-size: 25px !important;
    }
  }
}
li-ionic4-datepicker-modal {
  .dp-selecteddate,
  ion-title,
  ion-footer ion-button::part(button) {
    color: #fff !important;
  }
}
ion-card {
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 5px -2px;
}
.h100 {
  height: 100px !important;
}
.h50 {
  height: 50px !important;
}
.hauto {
  height: auto !important;
}
.main_form_page {
  ion-item {
    --background: transparent;
  }
  .error {
    color: red;
  }

  background: #ffffff;
  padding-top: 0;

  textarea {
    width: 100%;
  }
  .left-icon {
    position: absolute;
    left: 15px;
    top: 30%;
    font-size: 22px;
  }
  ion-row,
  .input,
  ion-select,
  ion-input,
  li-ionic4-datepicker input,
  textarea {
    background-color: #f2f3f6 !important;
    border: 0;
  }
  .input:focus,
  textarea:focus {
    box-shadow: 0 0 0;
  }
  ion-row-textarea {
    height: 90px;
  }
  .tab_accbh,
  ion-row {
    margin: 20px 0px;
    border-radius: 5px;
    height: 65px;
  }
}
li-ionic4-datepicker {
  background: transparent;
}
li-ionic4-datepicker:focus + .fakePlaceholder {
  display: none;
}
.fakePlaceholder {
  color: #788188;
  position: absolute;
  top: 30%;
  left: 15px;
  z-index: 1;
}
ion-select,
ion-input {
  --placeholder-opacity: 1 !important;
  color: #788188 !important;
}
.img-upload {
  justify-content: end;
  align-items: center;
  display: flex;
  div {
    background: #fff;
    padding: 20px;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    position: relative;
  }
  ion-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
}
.btn-create {
  display: flex;
  justify-content: center;
  align-items: center;
}
ion-calendar {
  .title {
    background: var(--ion-color-secondary) !important;
    margin: 10px;
    border-radius: 5px;
    ion-icon {
      background: #fff;
      border-radius: 50%;
      padding: 3px;
    }
    .switch-btn {
      color: #fff !important;
    }
  }
}

.on-selected {
  p {
    color: #fff !important;
  }
}
.week-title {
  color: #000 !important;
}
button.days-btn.red {
  background-color: var(--ion-color-danger) !important;
  p {
    color: #fff !important;
  }
}
button.days-btn.green {
  background-color: var(--ion-color-success) !important;
  p {
    color: #fff !important;
  }
}
button.days-btn {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50px !important;
}
.days {
  // height: 50px !important;
  margin: 13px 0px !important;
}
.days-box {
  padding: 0 !important;
}
.attendance-detail-class {
  app-attendance-detail {
    --border-radius: 10px;
  }
  .sc-ion-modal-md-h {
    --border-radius: 10px;
    --overflow: scroll;
  }
  h2 {
    line-height: 0.4;
  }
}

.modal-smaller {
  --height: 70% !important;
  --width: 90% !important;
}
.modal-bigger {
  --height: 95% !important;
  --width: 95% !important;
}

.modal-medium {
  --height: 80% !important;
  --width: 90% !important;
}

.modal-smallest {
  --height: 50% !important;
  --width: 90% !important;
}
.modal-extra-smallest {
  --height: 30% !important;
  --width: 50% !important;
}
.expense-detail-class {
  // --height: 30% !important;
  // --width: 50% !important;
  .modal-wrapper {
    border-radius: 20px;
  }
  ion-content {
    --padding-top: 20px;
    --padding-bottom: 5px;
    --padding-start: 30px;
    --padding-end: 30px;
  }
}
.balance-transfer-modal-class {
  --max-height: 40%;
  --max-width: 90%;
  .modal-wrapper {
    border-radius: 5px;
  }
}

.drp-dwn {
  position: relative;
  ion-row {
    margin: 0 !important;
    height: auto !important;
  }

  .tab_accbh {
    color: white;
    overflow: hidden;
    margin: 15px 0px;
    // border-radius: 8px;
    border: 1px solid #f1fbfd;
    background: #f2f3f6;
  }
  .tab-label-accbh,
  .transfer_amount_style {
    // width: 100%;
    display: flex;
    position: relative;
    padding: 1em;
    background: #f2f3f6;
    cursor: pointer;
    color: #000;
    transition: 0.2s linear;
  }

  .tab-label-accbh::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
    float: right;
    right: 22px;
    top: 25%;
    position: absolute;
    transform: rotate(90deg);
    background: #fff;
    padding: 7px;
    border-radius: 100%;
    display: table-cell;
    vertical-align: middle;
    line-height: 18px;
  }

  .tab-content-accbh {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: #f2f3f6;
    transition: all 0.35s;
  }

  .tab-close-accbh {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
  }

  .input_checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tab-close-accbh:hover {
    background: #1a252f;
  }

  .input_checkbox:checked + .tab-label-accbh::after {
    transform: rotate(270deg);
  }

  .input_checkbox:checked ~ .tab-content-accbh {
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    padding-top: 0;
    position: absolute;
    z-index: 3;
    width: 100%;
    box-shadow: inset 0 -7px 15px -7px rgb(120 144 156 / 44%);
    border-radius: 0 0 8px 8px;
  }

  .input_text {
    background-color: #f2f3f6 !important;
    border: 0;
    width: 100%;
  }

  .input_text:focus {
    box-shadow: 0 0 0;
    border: 0;
  }
  .transfer_btn {
    margin: 30px 0;
  }
  .event_content {
    padding: 15px;
  }
}

input:focus,
textarea:focus {
  outline: 0;
}
.display_content {
  display: contents;
}
.loadingspinner,
.infinite-scroll-content-md .infinite-loading-text {
  --color: #fff;
  color: #fff;
}

.full-page-modal-class {
  --width: 100%;
  --height: 100%;
  --min-width: 100%;
  --min-height: 100%;
}

.drp-header-text ion-text {
  font-size: 16px;
  font-weight: bold;
}

.view-item-alert {
  .alert-wrapper {
    min-width: 300px;
  }
  ion-list {
    background: transparent;
  }
  ion-item::part(native) {
    background: transparent;
  }
  ion-icon[name~="trash-outline"] {
    color: red;
    font-size: 18px;
  }
  .alert-head {
    padding: 16px;
  }
  ion-label {
    font-size: 15px !important;
  }
}
